import React, { useState, useEffect } from "react";
import { Authenticator } from "@aws-amplify/ui-react"; // Import required components
import { Hub, Auth } from "aws-amplify";
import { formFields } from "./formFields";
import HeaderBar from "./HeaderBar"; // Make sure the path is correct

function CustomAuthenticator({ children }) {
  const [authState, setAuthState] = useState("loading");
  const [user, setUser] = useState(null); // Add user state

  useEffect(() => {
    // Check the current authentication status when the app starts
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // User is authenticated
        setUser(user); // Set user details
        setAuthState("signedIn");
      })
      .catch((err) => {
        // User is not authenticated
        setAuthState("signIn");
      });

    // Handle authentication events
    const handleAuthEvents = (data) => {
      switch (data.payload.event) {
        case "signIn":
          setUser(data.payload.data); // Set user details on sign-in
          setAuthState("signedIn");
          break;
        case "signOut":
          setUser(null); // Reset user details on sign-out
          setAuthState("signIn");
          break;
        default:
          break;
      }
    };

    // Subscribe to 'auth' events
    const listener = Hub.listen("auth", handleAuthEvents);

    // Cleanup listener on component unmount
    return () => Hub.remove("auth", listener);
  }, []);

  // Function to handle sign out
  const handleSignOut = async () => {
    try {
      setAuthState("loading");
      await Auth.signOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const authPanelStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f4f4f8",
  };

  const titleBlock = {
    marginBottom: "1em",
    color: "#333",
    textAlign: "center",
  };

  const titleStyle = {
    fontSize: "2em",
    color: "#333",
    marginBottom: "-.5em",
  };

  function renderContent() {
    if (authState === "signedIn") {


      // Ensure user.signInUserSession and its nested properties are defined before checking for groups
      const groups = user.signInUserSession?.accessToken?.payload["cognito:groups"];

      // NOTE: We are temporarily disabling the group check for now
      // if (groups && groups.includes("Approved_Users")) {
      if (true) {
        return <div>{children}</div>;
      } else {
        // User is signed in but not in the 'Approved_Users' group
        return (
          <div style={authPanelStyle}>
            <div style={titleBlock}>
              <h1 style={titleStyle}>DLOM Signin</h1>
              <p>
                <em>Discounts for Lack of Marketability</em>
              </p>
            </div>
            <p>Pending Approval...</p>
            <p>
              You are not authorized to view this page. Please contact:{" "}
              <a href="mailto:ashok.abbott@bizvalinc.com" target="_blank" rel="noopener noreferrer">
                ashok.abbott@bizvalinc.com
              </a>{" "}
              to request approval.
            </p>
            <p>After being approved, try signing out and back in again.</p>
          </div>
        );
      }
    }

    // You can add more conditions here if there are other auth states to handle
    // For now, return null if none of the above conditions are met
    return null;
  }

  return (
    <div className="App">
      {authState === "signedIn" && <HeaderBar userEmail={user ? user.attributes.email : ""} onSignOut={handleSignOut} />}

      {/* When loading or any state other than 'signedIn', display the Authenticator */}
      {(authState === "loading" || authState !== "signedIn") && (
        <div style={authPanelStyle}>
          <div style={titleBlock}>
            <h1 style={titleStyle}>DLOM Signin</h1>
            <p>
              <em>Discounts for Lack of Liquidity and Marketability</em>
            </p>
          </div>
          {/* https://ui.docs.amplify.aws/react/connected-components/authenticator/configuration */}
          <Authenticator signUpAttributes={["family_name", "given_name", "custom:organization"]} formFields={formFields} />{" "}
          {/* signUpAttributes={["family_name", "given_name"]} */}
        </div>
      )}

      {renderContent()}
    </div>
  );
}

export default CustomAuthenticator;
